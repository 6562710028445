
/**
 * @name: walletRules
 * @author: Gzm
 * @date: 2023-06-02 09:26
 * @description：营销管理-钱包充值规则
 * @update: 2023-06-02 09:26
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ILevel} from "@/apis/customer/level/types";
import config from "@/config";
import {checkPositiveInteger} from "@/constants/validators"
import {
  queryCoachSettlementByPageApi,
  userWalletSetupCreateApi,
  userWalletSetupRemoveApi
} from "@/apis/marketingManage/rules";
import {IWalletRules} from "@/apis/marketingManage/rules/types";
import {Message} from "element-ui";

@Component({})
export default class LevelPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ILevel[] = []
  // 表单
  modelForm: Partial<ILevel> = {}
  // 查询参数
  queryParam = {}
  queryForm: any = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    searchBox: false,
    labelWidth: '120px',
    addTitle: '新增充值规则',
    editTitle: '编辑会员等级',
    menu: false,
    column: [
      {
        "label": "充值金额(元)",
        "prop": "rechargePrice",
        "align": "center",
        "span": 12,
        "maxlength": 20,
        "rules": [
          {required: true, message: "请输入充值金额", trigger: 'blur'},
          {validator: checkPositiveInteger, trigger: 'blur'}
        ]
      },
      {
        "label": "钱包金额(元)",
        "prop": "walletPrice",
        "align": "center",
        "span": 12,
        "maxlength": 9,
        "type": "number",
        "rules": [
          {required: true, message: "请输入钱包金额", trigger: 'blur'},
          {validator: checkPositiveInteger, trigger: 'blur'}
        ]
      }, {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true,
        addHide: true
      },
    ]
  }


  rowSave(form: IWalletRules, done: Function, loading: Function) {
    //@ts-ignore
    if (form.walletPrice < form.rechargePrice) {
      loading()
      return Message.error('钱包金额不能小于充值金额')
    }
    userWalletSetupCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("新增成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    }).catch(() => {

    })
  }

  /**
   * 删除
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否确认删除该规则?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      userWalletSetupRemoveApi(id).then(e => {
        if (e) {
          Message.success("删除成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  getList() {
    this.tableLoading = true
    queryCoachSettlementByPageApi(this.queryForm).then(e => {
      this.tableLoading = false
      this.tableTotal = e.total
      //@ts-ignore
      this.tableData = e.list;
    })
  }

  created() {
    this.getList()
  }
}
